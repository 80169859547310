import React, { Component } from 'react';
import whatsapp from "./images/whatsapp.png";

class Footer extends Component {
  
  
        /* 
      <footer className="page-footer font-small pt-4" style={{backgroundColor: '#F2ECBA', color: '#564020'}}>
        <div className="container-fluid text-center text-md-left">
          <div className="row" style={{display: 'none'}}>
            <div className="col-md-6 offset-3 mt-md-0 mt-3">
              <h6 className="text-uppercase font-weight-bold">WholeGrains</h6>
              <p className="small-text">Pakistan&apos;s First Whole Wheat Chapatis, that offer the convenience of being ready in minutes, hot and fresh when you need them.</p>
            </div>
            <hr className="clearfix w-100 d-md-none pb-3" />
            <div className="col-md-6 offset-3 mb-md-0 mb-3">
              <h6 className="text-uppercase font-weight-bold">Contact Us</h6>
              <p className="small-text">Email: info@wholegrains.pk &nbsp; Ph: +92-334-515-4111</p>
            </div>
          </div>

            <div className="row text-center">
              <div className="col-md-6 offset-3 py-3">
                <div className="mb-5 flex-center">
                  <a className="fb-ic" href="http://www.facebook.com/wholegrains">
                    <i className="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                  </a>
                  <a className="tw-ic" href="https://twitter.com">
                    <i className="fab fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                  </a>
                  <a className="ins-ic" href="https://www.instagram.com/wholegrains.pk/">
                    <i className="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                  </a>
                  <a className="pin-ic" href="https://www.pinterest.com/">
                    <i className="fab fa-pinterest fa-lg white-text fa-2x"> </i>
                  </a>
                </div>
              </div>
            </div>

        </div>
        <div className="footer-copyright text-center py-3">© 2019 Copyright:
          &nbsp;<a className="color-brown" href="http://www.wholegrains.pk">www.wholegrains.pk</a><br/>
          <span style={{fontSize: 8, color: '#b8b8b8'}}>
          <a href="https://www.freepik.com/free-photos-vectors/background">Background vector created by freepik - www.freepik.com</a>
          </span>
        </div>
      </footer>*/
      

  render() {
    return (
      <footer id="footer" className="section-bg">
        <div className="footer-top">
            <div className="container">

                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="footer-info">
                                    <h3>WHOLE GRAINS</h3>
                                    <p>Manufacturers of Organic, Healthy and Wholesome food products</p>
                                </div>

                                <div className="footer-newsletter">
                                    <h4>Join Us on Facebook</h4>
                                    <p>We frequently post announcements and recipes on our facebook page. Join us there.</p>
                                    <a href="http://www.facebook.com/wholegrains">WholeGrains on Facebook</a>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="footer-links">
                                    <h4>Useful Links</h4>
                                    <ul>
                                        <li><a style={{color: '#212529'}} href="/">Home</a></li>
                                        <li><a style={{color: '#212529'}} href="#products">Products</a></li>
                                        <li><a style={{color: '#212529'}} href="#whyus">Why WholeGrains</a></li>
                                        <li><a style={{color: '#212529'}} href="#storelocator">Store Locator</a></li>
                                    </ul>
                                </div>

                                <div className="footer-links">
                                    <h4>Contact Us</h4>
                                    <p>
                                        30-D Capt Umar Zeb Sh Rd<br/>
                                        DHA-1 Islamabad<br/>
                                        Pakistan<br/>
                                        <strong style={{color: '#846552'}}>Phone:</strong> +92 315 519 9979<br/>
                                        <strong style={{color: '#846552'}}>Email:</strong> info@wholegrains.pk<br/>
                                    </p>
                                </div>

                                <div className="social-links">
                                    <a href="https://twitter.com/WholeGrainsPk" className="twitter"><i className="fa fa-twitter"></i></a>
                                    <a href="https://www.facebook.com/wholegrains" className="facebook"><i className="fa fa-facebook"></i></a>
                                    <a href="https://www.instagram.com/wholegrains.pk/" className="instagram"><i className="fa fa-instagram"></i></a>
                                    <a href="https://www.youtube.com/channel/UCXFsADpADb1pAq9Up8ZuMrw" className="linkedin"><i className="fa fa-youtube"></i></a>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="col-lg-4">
                        <div className="form">
                            <img src={whatsapp} alt="" className="img-fluid" style={{height: 450}} />                            
                        </div>
                    </div>
                    <div className="col-lg-1"></div>



                </div>

            </div>
        </div>

        <div className="container">
            <div className="copyright">
                &copy; Copyright <strong>WholeGrains</strong>. All Rights Reserved
            </div>
            <div className="credits">
                <a href="https://bootstrapmade.com/">Designed by BootstrapMade</a>
            </div>
        </div>
    </footer>
      
    );
  }
}

export default Footer;