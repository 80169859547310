import React, { useState } from 'react';

function Stepper(props) {
    
    const [count, setCount] = useState(props.initialValue);
    const [forProduct, setForProduct] = useState(props.forProduct);
    const [onChangeFunc] = useState(() => props.onChange);

    const handlePlus = event => {
        if (count<60){
            var newCount = count+1;
            setCount(newCount);
            onChangeFunc(forProduct, newCount);
        }
    }

    const handleMinus = event => {
        if (count>1){
            var newCount = count-1;
            setCount(newCount);
            onChangeFunc(forProduct, newCount);
        }
    }

    const onChange = e => {
        let newCount = e.target.value;
        setCount(newCount);
        onChangeFunc(forProduct, e.target.value);
    }
      
    return (
        <div className="flex-container" style={{}}>
            <div className="d-flex flex-row justify-content-center align-items-center" >
                <button onClick={handleMinus} style={{fontSize: 18, color: 'inherit', backgroundColor: 'inherit', marginTop: 0, paddingTop: 0, 
                    paddingLeft: 9, paddingRight: 9, fontWeight: 'bold', border: '1px solid #7F4F2F', width: '35px', height: '35px'}}>
                -
                </button>
                <input type="number"
                        style={{textAlign: 'center',
                        borderTop: '1px solid #7F4F2F',
                        borderBottom: '1px solid #7F4F2F',
                        borderLeft: '0px none',
                        borderRight: '0px none',
                        margin: 0,
                        padding: 0,
                        width: 70,
                        color: '#7F4F2F',
                        height: 35,
                        fontSize: 16,
                        borderSize: 0, backgroundColor: 'inherit'}}
                        min="2" 
                        max="50"
                        value={count} 
                        onChange={onChange}></input>
                
                <button onClick={handlePlus} style={{fontSize: 18, color: 'inherit', backgroundColor: 'inherit', marginTop: 0, paddingTop: 0, 
                    paddingLeft: 9, paddingRight: 9, fontWeight: 'bold', border: '1px solid #7F4F2F', width: '35px', height: '35px'}}>
                +
                </button>
            </div>
        </div>
    );
}




// const handleMinus = event => {
//     if (this.state.quantity>2){
//         this.setState({quantity: this.state.quantity-1}, () => this.updateTotal());
//     }
// }


export default Stepper;