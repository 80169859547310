import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Redirect} from "react-router";
import * as moment from 'moment';
import { connect } from 'react-redux';
import LoginModal from './LoginModal';
import {createUser} from "./modules/user";
import { bindActionCreators } from "redux";
import { createOrder } from "./modules/cart";
// import rotipack from "./images/P01-Icon.png";
import './Order.css';
//import {products} from "./modules/cart";
import axiosconfig from "./modules/axiosconfig";

class Checkout extends Component {

  constructor(props) {
    super(props);
    this.deliveryWindows = [];
    this.state = {
      email: '',
      name: '',
      nameInvalid: 'none',
      phone: '',
      phoneInvalid: 'none',
      phoneInvalidMessage: 'Phone is required',
      whatsapp: '',
      street: '',
      streetInvalid: 'none',
      city: 'Rawalpindi',
      latitude: 33.539416,
      longitude: 73.098749,
      addressNotes: '',
      packCharges: 500,
      personId: 0,
      buttonValue: 'Place Order',
      buttonDisabled: '',
      
      quantity: 4,
      deliveryCharge: 250,
      deliveryDate: this.getDeliveryOptions()[0].val,
      createdDate: moment().format('ddd D MMM, YYYY'),
      productPrice: 500,
      totalAmount: 0,
      status: 0,
      password: '',
      loginButtonDisabled: '',
      passwordClass: 'form-control',

      errorMessage: '',
      submitted: false,
      redirectToConfirmation: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createOrder = this.createOrder.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  orderCreationCompleted() {
    let nextStep = "/confirmation";
    /*if (this.props.user.isLoggedIn){
      if (this.props.user.latitude===0 || this.props.user.latitude===33.53941600000001)
        nextStep = "/orderstep3";
      else
        nextStep = "/confirmation";
    }*/
    document.location.href = nextStep;
  }

  getDeliveryOptions(){
    var deliveryDate = [];
    var now = moment();
    var i;
    for (i = 0; i < 34; i++) {
      now.add(1,'days');
      if (now.day() === 2 || now.day() === 6)
      {
        deliveryDate.push({
          val: now.format('YYYY-MM-DD'),
          display: now.format('ddd D MMM, YYYY')
        });
      }
    }
    return deliveryDate;
  }

  

  handleSubmit(event) {
    event.preventDefault();
    let isValid = true;
    if (!this.props.user.isLoggedIn){
      if (!this.state.phone){
        this.setState({phoneInvalid: 'block', phoneInvalidMessage: 'Phone is required'});
        isValid = false;
      }
      else
      {
        this.setState({phoneInvalid: 'none'});
      }
      if (!this.state.name){
        this.setState({nameInvalid: 'block'});
        isValid = false;  
      }
      else{
        this.setState({nameInvalid: 'none'});
      }
      if (!this.state.street){
        this.setState({streetInvalid: 'block'});
        isValid = false;  
      }
      else{
        this.setState({streetInvalid: 'none'});
      }
      if (!isValid){
        return;
      }
      this.setState({submitted: true, errorMessage: ''});
      this.props.createUser({
        email: "",
        name: this.state.name,
        phone: this.state.phone,
        street: this.state.street,
        city: this.state.city,
        latitude: "33.539416",
        longitude: "73.098749",
        password: ""
      })
      .then((err)=>{
        if (err){
          this.setState({submitted: false, errorMessage: err});
        }
        else{
          this.createOrder();
        }
      })
    }
    else {
      //User is logged in. Just create order
      this.createOrder();
    }
  }

  createOrder = () => {
    var orderRequest = {
        userId: this.props.user.id,
        deliveryCharges: this.props.cart.deliveryCharges,
        amount: this.props.cart.amount,
        orderDetails: this.props.cart.items
    }
    this.props.createOrder(orderRequest)
    .then((result)=>{
      if (result)
        this.setState({submitted: false, errorMessage: result});
      else
        this.setState({redirectToConfirmation: true, submitted: false, errorMessage: ''});

    })
    .catch((err)=>{
      this.setState({submitted: false, errorMessage: err});
    });
  }

  showAddressForm = () => {
    return (
    <>
      <div className="p-2">
        <input
            type="text"
            required
            value={this.state.name}
            onChange={this.handleChange}
            className="form-control"
            id="name"
            name="name"
            placeholder="Full Name"
        />
        <div style={{display: this.state.nameInvalid}} className="error">
          Name is required.
        </div>
      </div>

      <div className="p-2">
        <textarea
            className="form-control"
            id="street"
            name="street"
            placeholder="Address"
            required
            value={this.state.street}
            onChange={this.handleChange}
        ></textarea>
        <div style={{display: this.state.streetInvalid}} className="error">
          Address is required.
        </div>
      </div>

      <div className="p-2">
        <select
            className="custom-select d-block w-100"
            id="city"
            name="city"
            required
            value={this.state.city}
            onChange={(e) => {this.setState({city: e.target.value})}}
        >
            <option>Rawalpindi</option>
            <option>Islamabad</option>
        </select>
      </div>
    </>);
  }

  contact = () => {
    return (
    <>
      <div style={{fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
              {(this.props.user.isLoggedIn) && 
                <div className="p-2" style={{fontSize: 16}}>
                  {this.props.user.name}<br/>
                  <span style={{fontWeight: 'normal'}}>
                  Phone: {this.props.user.phone}<br/>
                  {this.props.user.street}<br/>{this.props.user.city}</span>
                </div>}
              {(!this.props.user.isLoggedIn) && 
              <div className="p-2">
                <div className="d-flex flex-row justify-content-start">
                  <div className="form-control" style={{backgroundColor: '#E0E0E0', display: "inline",
                    width: "15%", height: 38, border: '1px solid #CED4DA', padding: 7, fontSize: 13}}>+92</div>
                  <input
                      type="tel"
                      className="form-control"
                      id="phone"
                      name="phone"
                      placeholder="Mobile Phone"
                      required
                      value={this.state.phone}
                      onChange={this.handleChange}
                      pattern="0?\d\d\d\s?\d{4,8}"
                      style={{ display: "inline", width: "85%" }}
                  />
                </div>
                <div style={{display: this.state.phoneInvalid}} className="error">
                  Phone is required.
                </div>
              </div>
              }
            </div>
            <div className="p-2">
              <LoginModal/>
            </div>
    </>
    );
  }

  orderSummary = () => {
    return (
      <>
      {
                  this.props.cart.items.map((item, index) => {return (
                    <div className="row" key={index} style={{fontFamily: 'gothamXLight', fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
                      <div className="col-12 col-md-4" style={{textAlign: 'left'}}>
                          {item.title}
                          <br/>
                          {item.description}
                      </div>
                      <div className="col-8 col-md-4">
                          QTY: {item.quantity}
                      </div>
                      <div className="col-4 col-md-4">
                          PKR. {item.subtotal}
                      </div>
                  </div>
                  )})
                }
                
                <div className="row">
                  <div className="col-12">
                    &nbsp;
                  </div>
                </div>
                <div className="row" style={{fontFamily: 'gothamXLight', fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
                    <div className="col-md-4 d-md-block d-none">&nbsp;</div>
                    <div className="col-md-4 col-8">Delivery Charges:</div>
                    <div className="col-md-4 col-4">PKR. {this.props.cart.deliveryCharges}</div>
                </div>
                
                <div className="row" style={{marginTop: 5, fontFamily: 'gothamXLight', fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
                    <div className="col-md-4 d-md-block d-none">&nbsp;</div>
                    <div className="col-md-4 col-8">Total:</div>
                    <div className="col-md-4 col-4" style={{borderTop: '1px solid #7E4D2D'}}>PKR. {this.props.cart.amount}</div>
                </div>

                <div className="row">
                    <div className="col-4" style={{marginTop: 30}}>
                      <a href="/cart">
                            Return to Cart
                      </a>
                    </div>
                    <div className="col-8" style={{marginTop: 30}}>
                        <button className="btn btn-primary my-2 my-sm-0"
                        disabled={!axiosconfig.enabled || this.state.submitted} 
                        onClick={this.handleSubmit}
                        type="submit">PLACE ORDER</button>
                    </div>
                </div>
      </>
    );
  }

  render() {
    return (
      <div className="container" style={{ padding: 0 }}>
        {this.state.redirectToConfirmation && <Redirect to="/confirmation"/>}
        <div className="row">
            <div className="col-12">
                &nbsp;<br/>&nbsp;
            </div>
        </div>
        <header className="section-header" style={{marginBottom: 25}}>
          <h3>Checkout</h3>
        </header>

        <div className="row">
            <div className="col-12">
                <span className="text-danger">{this.state.errorMessage}</span>
            </div>
        </div>

        <div className="d-block d-md-none">
          <div className="row">
              <div className="col-10 offset-1 tableHeading bottomBorder">
              CONTACT
              </div>
              <div className="col-10 offset-1">
              {this.contact()}
              </div>
              <div className="col-10 offset-1 tableHeading bottomBorder">
              PAYMENT
              </div>
              <div className="col-10 offset-1 brownText">
              Payment Method:{" "}Cash-On-Delivery
              </div>
          </div>
          <div className="row">
              
              {
                !this.props.user.isLoggedIn &&
              <>
                <div className="col-10 offset-1 tableHeading bottomBorder">
                DELIVERY ADDRESS
                </div>
                <div className="col-10 offset-1 brownText">
                  {(!this.props.user.isLoggedIn) && <div>{this.showAddressForm()}</div>}
                </div>
              </>
              }

              <div className="col-10 offset-1 tableHeading bottomBorder">
              ORDER SUMMARY
              </div>
              <div className="col-10 offset-1 brownText">
              {this.orderSummary()}
              </div>
          </div>

        </div>





        <div className="d-none d-md-block">

        <div className="row" style={{marginBottom: 20, fontSize: 12, fontWeight: 'bold', 
            color: '#7E4D2D'}}>
            <div className="col-6" style={{borderBottom: '1px solid #D2B883'}}>
            CONTACT
            </div>
            <div className="col-1">
            &nbsp;</div>
            <div className="col-5" style={{borderBottom: '1px solid #D2B883'}}>
            PAYMENT
            </div>
        </div>
        <div className="row">
          <div className="col-6" style={{textAlign: 'left'}}>
            {this.contact()}
          </div>
          <div className="col-1">&nbsp;</div>
          <div className="col-5 brownText">
            Payment Method:{" "}Cash-On-Delivery
          </div>
        </div>
        
        <div className="row">
            <div className="col-12">
                &nbsp;
            </div>
        </div>
        <div className="row" style={{marginBottom: 20, fontSize: 12, fontWeight: 'bold', 
            color: '#7E4D2D'}}>
            
            {
              !this.props.user.isLoggedIn &&
              <div className="col-6" style={{borderBottom: '1px solid #D2B883'}}>
              DELIVERY ADDRESS
              </div>
            }
            {
              this.props.user.isLoggedIn &&
              <div className="col-6">
              </div>
            }
            
            
            <div className="col-1">
            &nbsp;</div>
            <div className="col-5" style={{borderBottom: '1px solid #D2B883'}}>
            ORDER SUMMARY
            </div>
        </div>
        <div className="row">
            <div className="col-6 brownText" >
              {(!this.props.user.isLoggedIn) && <div>{this.showAddressForm()}</div>}
            </div>
            <div className="col-1"></div>
            <div className="col-5">
                {this.orderSummary()}
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                &nbsp;<br/>&nbsp;<br/>&nbsp;
            </div>
        </div>
        </div>
        <div>&nbsp;</div>
      </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createUser: bindActionCreators(createUser, dispatch),
    createOrder: bindActionCreators(createOrder, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));