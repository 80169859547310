import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);
    this.deliveryWindows = [];
    this.state = {
      
      deliveryCharge: 400,
      packCharges: 200,
      personId: 0,
      buttonValue: "Register & Place Order",
      error: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (
      !(
        this.state.name !== "" &&
        this.state.phone !== "" &&
        this.state.street !== "" &&
        this.state.city !== ""
      )
    ) {
      this.setState({
        error: "Please make sure all fields are populated"
      });
      return;
    }
    //fix phone
    this.setState(
      { whatsapp: this.internationalizePhone(this.state.phone), submitted: true },
      this.createPerson
    );
  }

  

  render() {
    return (
      <div className="flex-container mobile-container">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: "90%",
            margin: "auto"
          }}
        >
          <div>&nbsp;</div>
          <form
            className="needs-validation"
            onSubmit={this.handleSubmit}
          >
          <div className="h3 color-brown-light">
            <div className="col-12">SIGN UP</div>
          </div>
          <p>
            Please provide your contact information for order delivery, and
            future rewards and discounts
          </p>
          <div>&nbsp;</div>
          <div
            className="d-flex flex-column justify-content-left align-items-center"
            style={{
              width: "90%",
              margin: "auto"
            }}
          >
            {this.state.error && (
              <div style={{ width: "100%" }}>
                <span className="text-danger">{this.state.error}</span>
              </div>
            )}

            <div style={{ margin: "1vw", width: "100%" }}>
              <input
                type="text"
                required
                value={this.state.name}
                onChange={this.handleChange}
                className="form-control"
                id="name"
                name="name"
                placeholder="Full Name"
              />
            </div>

            <div style={{ margin: "1vw", width: "100%" }}>
              <div style={{backgroundColor: '#E0E0E0', display: "inline",
                  width: "15%", border: '1px solid #CED4DA', padding: 7}}>+92</div>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                placeholder="Mobile Phone"
                required
                value={this.state.phone}
                onChange={this.handleChange}
                pattern="0?\d\d\d\s?\d{4,8}"
                style={{ display: "inline", width: "85%" }}
              />
            </div>

            <div style={{ margin: "1vw", width: "100%" }}>
              <textarea
                className="form-control"
                id="street"
                name="street"
                placeholder="Address"
                required
                value={this.state.street}
                onChange={this.handleChange}
              ></textarea>
            </div>

            <div style={{ margin: "1vw", width: "100%" }}>
              <select
                className="custom-select d-block w-100"
                id="city"
                name="city"
                required
                value={this.state.city}
                onChange={this.handleCityChange}
              >
                <option>Rawalpindi</option>
                <option>Islamabad</option>
              </select>
            </div>

            <div style={{ margin: "1vw", width: "100%" }}>
              <button className="btn btn-primary my-2 my-sm-0"
                disabled={this.state.submitted} 
                onClick={this.handleSubmit}
                type="submit">Sign Up</button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">&nbsp;</div>
          </div>

          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
