import React, { Component } from 'react';
import axios from 'axios';
import * as moment from 'moment';

class Orders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: []
    }
    this.getOrders = this.getOrders.bind(this);
  }

  componentDidMount(){
    this.getOrders();
  }

  getOrders(){
    axios.get('http://localhost:65318/Order/orders/15')
    .then(res => {
      this.setState({orders: res.data.data});
      return res.data;
    })
    .catch(err => {
      return [];
    });
  }

  render() {
    const {orders}= this.state;
    const date = moment().format('ddd D MMM, YYYY');
    return (
      <div className="container" style={{marginTop: 10}}>
        <div className="row">
          <div className="col-12" style={{textAlign: 'center', padding: 5}}>
            WHOLEGRAINS ORDERS - {date}
            <hr/>
          </div>
        </div>
        <div className="row">
        {
          orders!=null &&
            orders.map((value, number)=>
            <div className="col-6" key={number} style={{padding: 8}}>
              <b>{value.name}</b> {value.phone}<br/>
              {value.street}{" "}{value.city}<br/>
              {value.orderDetails[0].quantity} packs - Rs. {value.amount}
            </div>
            )
        }
        </div>
      </div>
    );
  }


}

export default Orders;