import { createStore, compose, applyMiddleware } from "redux"
import wholegrainsApp from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from "redux-thunk"
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, wholegrainsApp)

//export default () => {
  let store;
  
  if (process.env.NODE_ENV === "production") {
    store = createStore(
      persistedReducer,
      compose(applyMiddleware(thunk)));
  }
  else{
    store = createStore(
      persistedReducer,
      compose(applyMiddleware(thunk),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
      ));
  }
    
  let persistor = persistStore(store)
  export default { store, persistor }
  //return store
//}

//export default store;