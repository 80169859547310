import React, { Component } from 'react';
import logo from "./images/plainlogo.png";
import loginbg from "./images/login-bg.jpg";
import axios from 'axios';
import { Link } from "react-router-dom";

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!( this.state.username!==''))
    {
      alert("Please make sure all required fields are populated.");
      return;
    }
    //fix phone
    this.setState({username: this.internationalizePhone(this.state.username)});

    //set axios to url encoded instead of json
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/token', this.state, config)
    .then(res => {
      document.location.href = "/";
    });
  }

  render() {
    return (
        
        <div className="container-fluid col-xl-5 col-lg-5 col-md-6 col-sm-8 col-xs-10 bg-light shadow" style={{borderRadius: '21px'}}>

        <div className="my-2">
          <img src={logo} alt="whole grains logo" style={{width: '10rem'}}></img>
          <p style={{fontSize: '0.9rem'}}>Login to place orders and manage subscription rewards and discounts</p>
          <p style={{fontSize: '0.9rem'}}>First Time? Go to <Link to="/register" style={{color: '#98511E', fontWeight: 'bold'}}>Guest Order / Sign Up</Link></p>
        </div>

        <div className="parallax" style={{position: 'fixed', top: 0, left: 0, width: '100%', zIndex: -1, backgroundImage: 'url('+loginbg + ')'}}></div>

        

        <form className="needs-validation" onSubmit={this.handleSubmit}>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            <label htmlFor="username">Email/Phone</label>
            <input type="text" className="form-control" id="username" name="username" placeholder="" required value={this.state.username} onChange={this.handleChange} />
            <div className="invalid-feedback">
              Email or Phone is required.
            </div>
          </div>
        </div>


        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control" name="password" id="password" placeholder="" required value={this.state.password} onChange={this.handleChange} />
            <div className="invalid-feedback">
              Password is required.
            </div>
          </div>
        </div>

        <hr/>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            <input type="submit" value="Log In" className="btn btn-primary btn-lg btn-block" style={{backgroundColor: '#98511E', borderColor: '#98511E'}}/> 
          </div>
        </div>

        <p>&nbsp;</p>
        </form>
        <p>&nbsp;</p>
        </div>

    );
  }
}

export default ForgotPassword;
