import React from 'react';

export default function StoryComponent(props) {
    return (
        <div className="container" style={{ textAlign: 'left', paddingTop: 20 }}>
            <div className="row">
                <div className="col-12">
                    {props.children}
                </div>
            </div>
            <div className="row" style={{paddingBottom: 20}}>
            <div className="col-12">
                &nbsp;
            </div>
            </div>
        </div>
    );
  }