import React, { Component } from 'react';
import StoryComponent from "./StoryComponent";

export default class ChakkiChapati extends Component {

  render() {
    return (
        <StoryComponent>
            <h3 className="text-primary">Chakki Chapati</h3>
            <p>Our Chapatis are made from Wholesome Chakki Atta, and are the healthiest form of Wheat products that are available in the market today.
                </p>

            <p>
                Each pack of Chapati's weigh 745 grams, and is for Rs. 185 + delivery charges.
            </p>

            <p>
                At this price, they are not only a superior and healtheir product to other frozen Naan products in the market, but also
                33% cheaper than brand name alternatives like Dawn Naan etc.
                </p>

            <p>
                It is very easy to prepare the Chapatis as shown in our <a href="/preparation">preparation videos</a>.
            </p>

            <p>
                Click here to <a href="/order">order your pack now</a>.
                </p>
        </StoryComponent>
    );
  }
}
