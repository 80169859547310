import React, { Component } from 'react';

class RegisterConfirmation extends Component {
  render() {
    return (
      <div className="App">

       <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
        <div className="my-3 py-3">
          <h2 className="display-5">Thank You for your Order.</h2>
          <p className="lead">We will notify you when your order is scheduled for delivery.</p>
          <p className="lead">If you have any questions, please call or WhatsApp +923155199979.</p>
          <p className="lead"><a href="/">Back to home</a></p>
        </div>
        <p>&nbsp;</p>
      </div>

      </div>
    );
  }
}

export default RegisterConfirmation;
