import React, { Component } from 'react';
import StoryComponent from "./StoryComponent";

export default class Faq extends Component {

  render() {
    return (
        <StoryComponent>
            <h3 className="text-primary">Frequently Asked Questions</h3>
           

                  <p className="cartTitle">1. How to prepare the Chappatis</p>
                  <p>
                    Heat the chapatis on a pan for thirty seconds each side, and
                    then give them heat directly on flame to make the Chapatis
                    inflate with steam ('Phooli Chapati').
                    <br />
                    Frozen or refrigerated Chapati can be heated directly, and
                    do not need a thawing period.
                  </p>
                  <p className="cartTitle">
                    2. How long do the Chapatis remain fresh
                  </p>

                  <p>
                    Since they are semi-cooked, they remain fresh in room
                    temperature for many hours.
                    <br />
                    In refrigerator, we recommend consuming them in four days.
                    <br />
                    It is best to put them in the freezer, where they can remain
                    fresh for many weeks.
                  </p>

                  <p className="cartTitle">3. Can we freeze the Chapatis</p>

                  <p>
                    Yes. We recommend storing the Chapatis in a freezer, where
                    they can be stored for many weeks. The advantage With frozen
                    chapatis is that they can directly be heated, and do not
                    need a thawing period.
                    <br />
                    They can also be refrigerated and remain fresh at least four
                    days.
                  </p>

                  <p className="cartTitle">4. Do you do home delivery</p>

                  <p>
                    Yes. We deliver in Rawalpindi &amp; Islamabad. There is a
                    variable delivery charge between Rs. 250/- to 400/-, depending on location.
                    <br />
                    Please check back for free delivery promotions.
                  </p>

                  
                  <p className="cartTitle">5. Where can we buy them</p>
                  <p>
                    We are working with local and chain stores to place our product. Currently, they are available via direct ordering
                    and delivery via our facebook page (facebook.com/wholegrains), or whatsapp, text, or phone to +92 334 515 4111.
                    <br />
                    We offer a very convenient subscription model, where you can register with your address and phone number, and then
                    whenever you need a refill, you just text us the number of packs and they are included in the scheduled delivery
                    in your area.
                </p>

                <p className="cartTitle">6. What are their ingredients</p>
                <p>
                    Our chappatis are made from Desi Chakki Atta and Water, with small amounts of Salt, flour and oil.
                </p>

                <p className="cartTitle">7. I Like your Chapatis. How can I promote your product</p>
                <p>
                    We rely on word of mouth marketing. You can share our page on facebook, instagram, or tell your friends and family
                    about it. Thanks you.
                </p>
        </StoryComponent>
    );
  }
}