import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import Main from "./Main";

class App1 extends Component {
  render() {
    return (
      <div className="App text-left">
        <Main></Main>
      </div>
    );
  }
}

export default withRouter(App1);
