const products = {
    chakkiChapatiLarge: {
        productCode: 'CCL101',
        title: 'CHAKKI CHAPATI REGULAR',
        description: '12 CHAPATI PACK',
        price: 395
    },
    chakkiChapatiFortified: {
      productCode: 'CCM004',
      title: 'CHAKKI CHAPATI FORTIFIED',
      description: '10 CHAPATI PACK',
      price: 375
    },
    chakkiChapatiMixed: {
      productCode: 'CCL005',
      title: 'CHAKKI CHAPATI MIX GRAIN',
      description: '10 CHAPATI PACK',
      price: 395
    },
    chakkiChapatiDiet: {
      productCode: 'CCM006',
      title: 'CHAKKI CHAPATI DIET',
      description: '10 CHAPATI PACK',
      price: 375
    }
};
export default products;