import React from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default function WithHeader(props) {
    return (
        <React.Fragment>
            <Header fixedHeader={props.fixedHeader}></Header>
            {props.children}
            <Footer></Footer>
        </React.Fragment>
    );
  }