import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App1 from './App1';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import storeObj from './store';
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render((
  <Provider store={storeObj.store}>
    
      <PersistGate loading={null} persistor={storeObj.persistor}>
      <BrowserRouter><App1/></BrowserRouter>
      </PersistGate>
    
  </Provider>), 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
