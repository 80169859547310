import React, { Component } from 'react';
import StoryComponent from "./StoryComponent";

export default class OurStory extends Component {

  render() {
    return (
        <StoryComponent>
            <h3 className="text-primary">Our Story</h3>
            <p>
                A lady who recently tried our product, told us that her son had previously stopped eating grains completely, but the texture and quality of our chapatis have re-introduced him to the joy of this fiber-rich, wholesome staple of our Pakistani cuisine.
            </p>
            <p>  In such small milestones, in making the lives of our women easier, and the diet of our community healthy, and wholesome, we continue to fulfill our mission.
                When my wife and I moved to US, the one thing we missed there the most was our traditional hot and fresh, handmade Chapatis. But in today’s urban lifestyle, especially in the busy schedule of our housewives and working women, it is almost impossible to find time for kneading the flour and preparing the dough for making Rotis, and add to that the difficulty of finding a reliable source of whole wheat. But at the same time, we love our children, and we don’t want to compromise on their health and nutrition to substitute their diet with processed foods full of additives, and preservatives.
            </p>
            <p>  Hence, in 2017, we pioneered the idea of semi-cooked Chapatis, made from the finest ingredients, that can bring the best of both worlds to our customers, fresh, rich and convenient. Our facebook research brought overwhelming appreciation for the idea, and hence we moved back to Pakistan to start Whole Grains, a mission driven company whose goal is to bring wholesome, quality food products to the community, where no woman has to ever make a choice between convenience vs the health of her loved ones.
            </p>
            <p>  With WholeGrains, you get the same ingredients we choose for our own children, packed in a convenience that you can never beat.
            </p>
            <p>  Please join us in our mission, and become part of a transformative journey towards health, and happiness.
            </p>
            <p><b>You can order our Chapatis <a href="/order">online</a> or by WhatsApp to +923155199979</b></p>
        </StoryComponent>
    );
  }
}
