import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from "./images/logo.png";
// import {Navbar, Nav} from "react-bootstrap";

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, mobileDisplay: 'none' };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getSize = () => {
    const width = this.state.width;
    if (width>800)
      return 13;
    else if (width>600 && width<800)
      return 11;
    else
      return 9
  }

  render() {
    var fixedHeaderClass = this.props.fixedHeader ? "" : "unfixHeaderClass";
    return (
    <header id="header" className={fixedHeaderClass}>

        <div id="topbar">
            <div className="container">
                <div className="social-links">
                    <a href="https://www.facebook.com/wholegrains" className="facebook"><i className="fa fa-facebook"></i></a>
                    <a href="https://www.youtube.com/channel/UCXFsADpADb1pAq9Up8ZuMrw" className="linkedin"><i className="fa fa-youtube"></i></a>
                    <a href="https://www.instagram.com/chakki_chappati/" className="instagram"><i className="fa fa-instagram"></i></a>
                </div>
            </div>
        </div>

        <div className="container">

            <div className="logo float-left">

                <a href="#header" className="scrollto"><img src={logo} alt="" className="img-fluid"/></a>
                
            </div>

            <nav className="main-nav float-right d-none d-lg-block">
                <ul>
                    <li className="active"><a href="#intro">Home</a></li>
                    <li><a href="#products">Products</a></li>
                    <li><a href="#whyus">Why WholeGrains</a></li>
                    <li><a href="#storelocator">Store Locator</a></li>
                    <li><a href="#products">Order Now</a></li>
                    <li><a href="/cart">Cart</a></li>
                </ul>
            </nav>

<div style={{display: this.state.mobileDisplay}}>
            <nav className="mobile-nav d-block d-lg-none">
                <ul>
                    <li className="active"><a href="#intro" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Home</a></li>
                    <li><a href="#products" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Products</a></li>
                    <li><a href="#whyus" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Why WholeGrains</a></li>
                    <li><a href="#storelocator" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Store Locator</a></li>
                    <li><a href="#products" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Order Now</a></li>
                    <li><a href="/cart" onClick={(e)=>{this.setState({mobileDisplay: 'none'})}}>Cart</a></li>
                </ul>
            </nav>
            </div>
            <button type="button" className="mobile-nav-toggle d-block d-lg-none" 
              onClick={(e) => this.state.mobileDisplay==='none' ? this.setState({mobileDisplay: 'block'}) : this.setState({mobileDisplay: 'none'})}><i className="fa fa-bars"></i></button>

        </div>
    </header>


  /*
    <div className="container-fluid" style={{padding: 0, margin: 0}}>
      

    
      <div className="d-flex flex-row justify-content-center" 
        style={{color: '#EA811E', fontFamily: 'gotham', fontSize: this.getSize(), padding: 20}}>
        <div className="p-4"><a href="home#products" style={{color: '#EA811E'}}>PRODUCTS</a></div>
        <div className="p-4"><a href="home#whywholegrains" style={{color: '#EA811E'}}>WHY WHOLEGRAINS</a></div>
        <a href="/"><img src={logo} alt="logo" style={{width: '18vw', height: '6vw'}} /></a>
        <div className="p-4"><a href="home#storelocator" style={{color: '#EA811E'}}>STORE LOCATOR</a></div>
        <div className="p-4"><a href="/cart" style={{color: '#EA811E'}}>CART</a></div>
      </div>
      
    </div>*/
);
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Header);