import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import logo from "./images/plainlogo.png";
import loginbg from "./images/login-bg.jpg";
import axios from 'axios';
import { connect } from 'react-redux';


class OrderStep2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      password: '',
      passwordClass: 'form-control',
      passwordConfirm: '',
      passwordConfirmClass: 'form-control'
    };
    this.handleSkip = this.handleSkip.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSkip(event) {
    let nextStep = "/orderstep3";
    if (this.props.user.isLoggedIn){
      if (this.props.user.latitude===0 || this.props.user.latitude===33.53941600000001)
        nextStep = "/orderstep3";
      else
        nextStep = "/confirmation";
    }
    document.location.href = nextStep;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.password===''){
      this.setState({'passwordClass': 'form-control is-invalid'} );
      return;
    }
    if (this.state.password!==this.state.passwordConfirm){
      this.setState({'passwordConfirmClass': 'form-control is-invalid'} );
      return;
    }
    let request = {
      id: this.props.id,
      password: this.state.password,
      email: this.state.email
    }
    axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/updateUser', request)
      .then(res => {
        if (res.status===200){
          this.props.dispatch({'type': 'LOGIN', 'data': res.data});
          this.handleSkip(null);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (

      <div className="App" style={{width:'100%', display: 'flex', justifyContent: 'center', margin: 10 }}>

        <div className="container-fluid col-xl-6 col-lg-10 col-md-10 col-sm-11 col-xs-12 bg-light shadow opaque" style={{borderRadius: '21px'}}>
        
        <div className="parallax" style={{position: 'fixed', top: 0, left: 0, width: '100%', zIndex: -1, backgroundImage: 'url('+loginbg + ')'}}></div>

        <div className="my-2">
          <img src={logo} alt="whole grains logo" style={{width: '10rem'}}></img>
        </div>

        <div className="bg-light mx-auto">

        <form className="needs-validation" onSubmit={this.handleSubmit}>
        <br/>
        
        <div className="row text-left my-2">
          <div className="col-10 offset-1">
            <p>
              <strong>
                Thank you! Order Placed Successfully.
              </strong>
            </p>
            <p>
              Please take a moment to secure your account with a password. Your account will allow you to place orders at click of a button, and also participate in
              rewards and discounts.
            </p>
          </div>
        </div>

        <div className="row text-dark text-left my-2">
          <div className="col-10 offset-1">
            <h5>Set A Password</h5>
          </div>
        </div>

        <div className="row text-dark text-left my-2">
          <div className="col-5 offset-1">
            <label htmlFor="name">Password</label>
            <input type="password" className={this.state.passwordClass} id="password" name="password" placeholder="" 
              value={this.state.password} onChange={this.handleChange} />
            <div className="invalid-feedback">
              Password required.
            </div>
          </div>

          <div className="col-5">
            <label htmlFor="name">Password Confirmation</label>
            <input type="password" className={this.state.passwordConfirmClass} id="passwordConfirm" name="passwordConfirm" 
              placeholder="" value={this.state.passwordConfirm} onChange={this.handleChange} />
            <div className="invalid-feedback">
              Password confirmation required.
            </div>
          </div>
        </div>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            &nbsp;
          </div>
        </div>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            Your login Id is your Phone Number which you provided. You can also provide an email as an alternative Login Id.
          </div>
        </div>


        <div className="row text-dark text-left my-2">
          <div className="col-5 offset-1">
            <label htmlFor="name">Login Id (Phone)</label>

            <input type="text" className="form-control" id="phone" name="phone" placeholder="" value={this.props.phone} 
              readOnly disabled />
            <div className="invalid-feedback">
            </div>
          </div>

          <div className="col-5">
            <label htmlFor="name">Email (Optional)</label>
            <input type="text" className="form-control" id="email" name="email" placeholder="" value={this.state.email} 
              onChange={this.handleChange} />
            <div className="invalid-feedback">
              Password confirmation required.
            </div>
          </div>
        </div>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            &nbsp;
          </div>
        </div>

        <div className="row text-dark text-left my-2">
          <div className="col-7 offset-1">
            <input type="submit" value="Set Password" className="btn btn-primary btn-lg btn-block" 
              style={{backgroundColor: '#98511E', borderColor: '#98511E', display: 'inline-block'}}/>
          </div>
          <div className="col-3">
            <input type="button" value="Skip" className="btn btn-primary btn-lg btn-block" onClick={this.handleSkip} 
              style={{backgroundColor: '#98511E', borderColor: '#98511E', display: 'inline-block'}}/>
          </div>
        </div>

        <p>&nbsp;</p>
        </form>
        </div>
        </div>
        <p>&nbsp;</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    phone: state.user.phone,
    id: state.user.id
  }
}

const mapDispatchToProps = {  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderStep2));