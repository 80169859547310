import axios from "axios";
import axiosconfig from '../axiosconfig';
import base64 from 'base-64';

// actions

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CHANGE_CITY = 'CHANGE_CITY'
export const RESETPIN = 'RESETPIN'

// operations

const login = requestObject => dispatch => {
  try {
    return axios
      .post(
        axiosconfig.api2 + "/Account/Login",
        requestObject
      )
      .then(res => {
          if (res.status === 200) {
            dispatch({'type': 'LOGIN', 'payload': res.data});
            return "";
          } else {
            return res.data;
          }
        }
      )
      .catch(err => {
        if (err && err.response && err.response.status){
          if (err.response.status===403){
            return "Incorrect user name or password";
          }
        }
        return err.message;
      });
  } catch (err) {
    return err;
  }
}

const resetPin = requestObject => dispatch => {
  try {
    axios
      .post(
        axiosconfig.api2 + "/Account/Resetpin",
        requestObject
      )
      .then(res => {
          if (res.status === 200) {
            dispatch({'type': 'RESETPIN'});
            return "";
          } else {
            return res.data;
          }
        }
      )
      .catch(err => {
        if (err && err.response && err.response.status){
          if (err.response.status===403){
            return "Incorrect user name or password";
          }
        }
        return err.message;
      });
  } catch (err) {
    return err;
  }
}

const createUser = requestObject => dispatch => {
  return axios
    .post(
      axiosconfig.api2 + "/Account",
      requestObject
    )
    .then(res => {
        if (res.status === 200) {
          dispatch({'type': 'LOGIN', 'payload': res.data});
          return "";
        } else {
          return res.data;
        }
      }
    )
    .catch(err => {
      if (err && err.response && err.response.status){
        if (err.response.status===409){
          return "A user with this phone number already exists. Please login or reset your Pin";
        }
      }
      return err.message;
    });
}

export {
  createUser,
  login,
  resetPin
};

// reducer

export default function user(state = { isLoggedIn: false, phone : '', id: 0, city: 'Rawalpindi' }, action) {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return Object.assign({}, state, {
        data: action.data
      });
    case LOGIN:
      let tokenString = action.payload.data;
      let tokenStringJsonPart = tokenString.substring(tokenString.indexOf(".")+1, tokenString.lastIndexOf("."));
      var tokenData =  JSON.parse(base64.decode(tokenStringJsonPart));
      let newState = Object.assign({}, state, tokenData, { isLoggedIn: true, token: tokenString });
      return newState;
    case LOGOUT:
          return Object.assign({}, state, { isLoggedIn: false, phone : '', id: 0, city: 'Rawalpindi' });
    case CHANGE_CITY:
      return Object.assign({}, state, {
        city : action.data });
    case RESETPIN:
      return state;
    default:
      return state;
  }
}

// selectors

export function getLoggedInUser(user) {
    return user;
}
