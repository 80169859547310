import { combineReducers } from 'redux';
import cart from "./modules/cart";
import user from "./modules/user";


const wholeGrainsApp = combineReducers({
  user,
  cart
})

export default wholeGrainsApp