
import React, {useState} from 'react';
import {Modal,Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import loading from './images/loading.gif';
import {login, resetPin} from "./modules/user";


function LoginModal({user, dispatch}) {
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phone === '') {
        setError("Please provide phone number");
        return;
    }
    if (password === '') {
        setError("Please provide password");
        return;
    }

    setSubmitted(true);

    let request = {
      phone: phone,
      password: password,
      grant_type: 'password',
      client_id: 'wholegrains'//client_id: '276cebf8-c199-420c-9ef6-6348b4c697ed'
    }
    dispatch(login(request))
    .then(err=>{
      if (err){
        setSubmitted(false);
        setError(err);
      }
      else{
        setSubmitted(false);
        setShow(false);
      }
    });
    
  };

  const requestPin = (event) => {
    if (phone.length < 9) {
        setError("Please enter complete phone number to obtain new Pin");
    } else {
        setError("New Pin Requested");
        dispatch(resetPin({phone: phone}));
    }
  }

  return (
    <>
      {!user.isLoggedIn && (
        <button onClick={handleShow} className="btn-link-large">
            Already Registered? Login
        </button>
      )}
      {user.isLoggedIn && (
        <button onClick={() => dispatch({'type': 'LOGOUT'})}
            className="btn-link-large"
            style={{paddingLeft: 0, backgroundColor: 'inherit', border: '0px none transparent'}}>
          (Not You? Logout)
        </button>
      )}
      

      <Modal show={show} onHide={handleClose} dialogClassName="modal-offset">
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#98511E', fontSize: '14pt'}}>Log In To WholeGrains</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <span style={{ color: '#CF511E'}}>{error}</span>
                    </div>
                    <div className="col-12 p-2">
                        <div className="d-flex flex-row justify-content-start">
                            <div className="form-control" style={{backgroundColor: '#E0E0E0', display: "inline",
                                width: "15%", height: 38, border: '1px solid #CED4DA', padding: 7, fontSize: 13}}>+92</div>
                            <input
                                type="tel"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Mobile Phone"
                                required
                                value={phone}
                                onChange={(e)=>setPhone(e.target.value)}
                                pattern="0?\d\d\d\s?\d{4,8}"
                                style={{ display: "inline", width: "85%" }}
                            />
                        </div>
                    </div>
                    <div className="col-12 p-2">
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Pin Code"
                            required
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
                    <div className="col-12">
                        Forgot Pin?{" "}
                        <button onClick={requestPin} className="btn-link-large">
                            Request New Pin
                        </button>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={submitted} style={{width: 130}}>
            <img src={loading} alt="..." style={{width: 20, display: submitted?'inline':'none'}} /> Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}


export default connect(mapStateToProps, null)(LoginModal);