import React, { Component } from 'react';
import StoryComponent from "./StoryComponent";

export default class Preparation extends Component {

  render() {
    return (
        <StoryComponent>
            <h3 className="text-primary">Preparation Instructions</h3>
           
    <p>Preparation is extremely easy. Checkout the Videos below, and see detailed instructions underneath.</p>

    <iframe title="preparation 1" width="560" height="315" src="https://www.youtube.com/embed/KzbLS3K0a4Q?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>

    <p>Another older video to show preparation (using a third-party product). This video was prepared befor our product launch,
        to demonstrate utility of such Chapatis</p>

    <iframe title="preparation 2" width="560" height="315" src="https://www.youtube.com/embed/FfOzoh8JpgQ?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                 
        </StoryComponent>
    );
  }
}