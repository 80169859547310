import React, { Component } from 'react';
import StoryComponent from "./StoryComponent";

export default class Preparation extends Component {

  render() {
    return (
        <StoryComponent>
            <h3 className="text-primary">Contacts</h3>
            <p>You can contact us from our <a href="http://www.facebook.com/wholegrains">facebook page</a></p>
            <p>Or call us at +92-315-5199979</p>
        </StoryComponent>
    );
  }
}