import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import rotiPack from "./images/P01-Icon.png";
import * as moment from 'moment';
import { connect } from 'react-redux';
import './Order.css';

class OrderSummary extends Component {

  constructor(props) {
    super(props);
    this.deliveryWindows = [];
    this.state = {
      email: '',
      name: '',
      phone: '',
      whatsapp: '',
      street: '',
      latitude: 33.539416,
      longitude: 73.098749,
      addressNotes: '',
      personId: 0,
      buttonValue: 'Place Order',
      buttonDisabled: '',
      errorMessage: '',
      quantity: this.props.cart.items[0].quantity,
      deliveryCharge: this.props.cart.items[0].delivery,
      deliveryDate: this.getDeliveryOptions()[0].val,
      createdDate: moment().format('ddd D MMM, YYYY'),
      productPrice: this.props.cart.items[0].productPrice,
      subtotal: this.props.cart.items[0].subtotal,
      totalAmount: this.props.cart.items[0].totalAmount,
      status: 0,
      password: '',
      loginButtonDisabled: '',
      passwordClass: 'form-control',
      phoneInvalid: 'none',
      phoneInvalidMessage: 'Phone is invalid'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    if (!this.props.user.isLoggedIn){
      //document.location.href="/login";
    }

  }
  
  handleLogin(event){
    event.preventDefault();
    let isValid = true;
    if (this.state.phone===''){
      this.setState({phoneInvalid: 'block', phoneInvalidMessage: 'Phone is invalid'});
      isValid = false;  
    }
    if (this.state.password===''){
      this.setState({passwordClass: 'form-control is-invalid'}); 
      isValid = false; 
    }
    if (isValid){
      this.setState({loginButtonDisabled: 'disabled'});
      let request = {
        username: this.state.phone,
        password: this.state.password,
        grant_type: 'password',
        client_id: 'wholegrains'
      }
      axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/token', request)
        .then(res => {
          this.props.dispatch({'type': 'LOGIN', 'data': res.data});
          this.setState({loginButtonDisabled: 'disabled'});
        });
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleLogOut(event) {
    this.props.dispatch({'type': 'LOGOUT'});
  }

  orderCreationCompleted() {
    let nextStep = "/confirmation";
    if (this.props.user.isLoggedIn){
      if (this.props.user.latitude===0 || this.props.user.latitude===33.53941600000001)
        nextStep = "/orderstep3";
      else
        nextStep = "/confirmation";
    }
    document.location.href = nextStep;
  }

  getDeliveryOptions(){
    var deliveryDate = [];
    var now = moment();
    var i;
    for (i = 0; i < 34; i++) {
      now.add(1,'days');
      if (now.day() === 2 || now.day() === 6)
      {
        deliveryDate.push({
          val: now.format('YYYY-MM-DD'),
          display: now.format('ddd D MMM, YYYY')
        });
      }
    }
    return deliveryDate;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.createOrder(this.props.user.id);
  }

  createOrder(personId){
    var orderState = {
      personId: this.state.personId,
      quantity: this.state.quantity,
      deliveryDate: this.state.deliveryDate,
      createdDate: this.state.createdDate,
      productPrice: this.state.productPrice,
      deliveryCharge: this.state.deliveryCharge,
      totalAmount: this.state.totalAmount,
      status: this.state.status,
      name : this.props.user.name,
      whatsapp : this.props.user.whatsapp,
      address : this.props.user.street + ' ' + this.props.user.city,
      latitude : this.props.user.latitude,
      longitude : this.props.user.longitude
    }
    let orderJson = Object.assign({}, orderState);
    orderJson.personId = personId;
    axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/createOrder', orderJson)
    .then(res => {
      this.orderCreationCompleted();
    })
    .catch(err => {
      this.setState({buttonDisabled: '', errorMessage: "An error occurred. Failed to create order"});
    });
  }

  
  SignedInUser(){
    return(
      <div className="row no-gutters" style={{textAlign: 'center', backgroundColor: '#FFFAF0', borderBottom: '1px solid #F4ECDC', padding: '0.5vw', color: '#91742D'}}>

      <div className="col-12" style={{color: '#F04830'}}>{this.state.errorMessage}</div>

      <div className="col-12">&nbsp;</div>
      
      <div className="col-1 text-left"></div>
      <div className="col-10 text-left" style={{borderRadius: 10, padding: 4, paddingLeft: '1rem', paddingBottom: '1rem'}}>
        <p style={{marginBottom: '0.2rem'}}><strong>Deliver To:</strong></p>
        
          <b>{this.props.user.name}</b> (Not You? <a href="/" onClick={this.handleLogOut}>Logout</a>)
          <br/>
          Phone: {this.props.user.phone}
          <br/>
          Address: {this.props.user.street}, {this.props.user.city}

      </div>
      <div className="col-1">&nbsp;</div>

      <div className="col-12">&nbsp;</div>

      <div className="col-1">&nbsp;</div>
      <div className="col-10">
        {
          /* eslint-disable jsx-a11y/anchor-is-valid */
        }
        <input type="submit" disabled={this.state.buttonDisabled} value={this.state.buttonValue} 
          className="btn btn-primary btn-lg btn-block" 
          style={{backgroundColor: '#98511E', borderColor: '#98511E', width: '100%'}}/>
      </div>
      <div className="col-1">&nbsp;</div>

      <div className="col-12">&nbsp;</div>
      </div>
    );
  }

  render() {
    return (

    <div className="container-fluid">
      
      <div className="row no-gutters align-items-center">
        <div className="col-sm-12" style={{marginTop: '1vw'}}>
          <div className="col-12 h2 color-brown-light">Order Summary</div>
        </div>  
      </div>

      <div className="d-block d-sm-block d-md-block d-lg-none">
      
      <div className="row no-gutters" style={{textAlign: 'center', backgroundColor: '#FFFAF0', borderBottom: '1px solid #F4ECDC', padding: '0.5vw', color: '#91742D'}}>
        <div className="col-12">
          <strong>Item</strong>
        </div>
        <div className="col-12">
          <img src={rotiPack} alt="Roti Pack" style={{width: '8rem'}} />
          <br/>
          Chakki Chappati
          <br/>
          Pack of 12
        </div>
        <div className="col-6">
          <strong>Price</strong>
        </div>
        <div className="col-6">
          <strong>Quantity</strong>
        </div>
        <div className="col-6">
          Rs. 125/-
        </div>
        <div className="col-6">
          
          <div style={{padding: '3px', border: '1px solid #F4ECDC', width: '33px', height: '33px', display: 'inline-block', textAlign: 'center'}}>{this.state.quantity}</div>
          
          <br/>
          <span className="footnote">(Minimum Qty: 2)</span>
        </div>
        <div className="col-6 text-left p-1">
          Subtotal
        </div>
        <div className="col-6 text-right p-1">
        Rs. {this.state.subtotal}/-
        </div>
        <div className="col-6 text-left p-1">
          Delivery Charge:
          <br/>
          <span className="footnote">(Free for 4 packs or more)</span>
        </div>
        <div className="col-6 text-right p-1">
          Rs. {this.state.deliveryCharge}/-
        </div>
        <div className="col-6 text-left p-1">
          Delivery Date:
          <br/>
          <span className="footnote">(Deliveries on Tue &amp; Sat)</span>
        </div>
        <div className="col-6 text-left p-1">
          <select id="deliveryDate" name="deliveryDate" value={this.state.deliveryDate} onChange={this.handleChange}>
            {this.getDeliveryOptions().map((slot) => <option key={slot.val} value={slot.val}>{slot.display}</option>)}
          </select>
        </div>
        <div className="col-6 text-left p-1">
          <strong>Total</strong>
        </div>
        <div className="col-6 text-right p-1">
        <strong>Rs. {this.state.totalAmount}/-</strong>
        </div>
      </div>
      
      </div>

      <div className="d-none d-lg-block">

      <div className="row no-gutters" style={{textAlign: 'center', backgroundColor: '#FFFAF0', borderBottom: '1px solid #F4ECDC', padding: '0.5vw', color: '#91742D'}}>
        <div className="col-3">
          <strong>Item</strong>
        </div>
        <div className="col-9">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <strong>Price</strong>
              </div>
              <div className="col-4">
                <strong>Quantity</strong>
              </div>
              <div className="col-4">
                <strong>Subtotal</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters" style={{textAlign: 'center', backgroundColor: '#FFFAF0', padding: '1vw'}}>
        <div className="col-3">
          <img src={rotiPack} alt="Roti Pack" style={{width: '8rem'}} />
          <br/>
          Chakki Chappati
          <br/>
          Pack of 12
        </div>
        <div className="col-9">
          <div className="container-fluid">
            <div className="row  no-gutters">
              <div className="col-4">
                Rs. {this.state.productPrice}/-
              </div>
              <div className="col-4">
                <div style={{padding: '3px', border: '1px solid #F4ECDC', width: '33px', height: '33px', display: 'inline-block', textAlign: 'center'}}>{this.state.quantity}</div>
              </div>
              <div className="col-4">
                Rs. {this.state.subtotal}/-
              </div>
            </div>
            <div className="row  no-gutters">
              <div className="col-12">
                &nbsp;
              </div>
            </div>
            <div className="row  no-gutters" style={{paddingTop: '12px', paddingBottom: '12px'}}>
              <div className="col-4">
                  &nbsp;
              </div>
              <div className="col-4 text-left pl-5">
                Delivery Charge:
                <br/>
                <span className="footnote">(Free for 4 packs or more)</span>
              </div>
              <div className="col-4">
                Rs. {this.state.deliveryCharge}/-
              </div>
            </div>
            <div className="row  no-gutters" style={{paddingBottom: '12px'}}>
              <div className="col-4">
                  &nbsp;
              </div>
              <div className="col-4 text-left pl-5">
                Delivery Date:
              </div>
              <div className="col-4">
                <select id="deliveryDate" name="deliveryDate" value={this.state.deliveryDate} onChange={this.handleChange}>
                  {this.getDeliveryOptions().map((slot) => <option key={slot.val} value={slot.val}>{slot.display}</option>)}
                </select>
              </div>
            </div>
            <div className="row  no-gutters" style={{paddingTop: '12px', paddingBottom: '12px'}}>
              <div className="col-4">
                  &nbsp;
              </div>
              <div className="col-4 text-left pl-5" style={{borderBottom: '1px solid #F4ECDC', borderTop: '1px solid #F4ECDC', paddingTop: '12px', paddingBottom: '12px'}}>
                <strong>Total:</strong>
              </div>
              <div className="col-4" style={{borderBottom: '1px solid #F4ECDC', borderTop: '1px solid #F4ECDC', paddingTop: '12px', paddingBottom: '12px'}}>
                <strong>Rs. {this.state.totalAmount}/-</strong>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>

      <form className="needs-validation" onSubmit={this.handleSubmit}>
        {this.props.user.isLoggedIn && this.SignedInUser()}
      </form>
      

        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart
  }
}

export default connect(mapStateToProps)(withRouter(OrderSummary));