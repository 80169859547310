import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import OrderComponent from "./OrderComponent";
import * as moment from 'moment';
import { connect } from 'react-redux';
import products from "./modules/products";
import rotipack from "./images/P01-Icon.png";
import './Order.css';

const pricePerPack = 125;

class Order extends Component {

  constructor(props) {
    super(props);
    this.deliveryWindows = [];
    this.state = {
      email: '',
      name: '',
      phone: '',
      whatsapp: '',
      street: '',
      latitude: 33.539416,
      longitude: 73.098749,
      addressNotes: '',
      packCharges: 500,
      personId: 0,
      buttonValue: 'Place Order',
      buttonDisabled: '',
      errorMessage: '',
      quantity: 4,
      deliveryCharge: 250,
      deliveryDate: this.getDeliveryOptions()[0].val,
      createdDate: moment().format('ddd D MMM, YYYY'),
      productPrice: 500,
      totalAmount: 500,
      status: 0,
      password: '',
      loginButtonDisabled: '',
      passwordClass: 'form-control',
      phoneInvalid: 'none',
      phoneInvalidMessage: 'Phone is invalid'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    if (!this.props.user.isLoggedIn){
      //document.location.href="/login";
    }

  }

  
  handleLogin(event){
    /*
    if (this.state.password===''){
      this.setState({passwordClass: 'form-control is-invalid'}); 
      isValid = false; 
    }
    if (isValid){
      this.setState({loginButtonDisabled: 'disabled'});
      let request = {
        username: this.state.phone,
        password: this.state.password,
        grant_type: 'password',
        client_id: 'wholegrains'
      }
      axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/token', request)
        .then(res => {
          this.props.dispatch({'type': 'LOGIN', 'data': res.data});
          this.setState({loginButtonDisabled: 'disabled'});
        });
    }*/
  }

  updateTotal(){
    const deliveryCharge = 250;
    this.setState({totalAmount: (this.state.quantity*pricePerPack) + deliveryCharge, packCharges: this.state.quantity*pricePerPack, deliveryCharge: deliveryCharge});
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleLogOut(event) {
    this.props.dispatch({'type': 'LOGOUT'});
  }

  orderCreationCompleted() {
    let nextStep = "/confirmation";
    if (this.props.user.isLoggedIn){
      if (this.props.user.latitude===0 || this.props.user.latitude===33.53941600000001)
        nextStep = "/orderstep3";
      else
        nextStep = "/confirmation";
    }
    document.location.href = nextStep;
  }

  getDeliveryOptions(){
    var deliveryDate = [];
    var now = moment();
    var i;
    for (i = 0; i < 34; i++) {
      now.add(1,'days');
      if (now.day() === 2 || now.day() === 6)
      {
        deliveryDate.push({
          val: now.format('YYYY-MM-DD'),
          display: now.format('ddd D MMM, YYYY')
        });
      }
    }
    return deliveryDate;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.createOrder(this.props.user.id);
  }

  createOrder(personId){
    var orderState = {
      personId: this.state.personId,
      quantity: this.state.quantity,
      deliveryDate: this.state.deliveryDate,
      createdDate: this.state.createdDate,
      productPrice: this.state.productPrice,
      deliveryCharge: this.state.deliveryCharge,
      totalAmount: this.state.totalAmount,
      status: this.state.status,
      packCharges: this.state.packCharges,
      name : this.props.user.name,
      whatsapp : this.props.user.whatsapp,
      address : this.props.user.street + ' ' + this.props.user.city,
      latitude : this.props.user.latitude,
      longitude : this.props.user.longitude
    }
    let orderJson = Object.assign({}, orderState);
    orderJson.personId = personId;
    axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/createOrder', orderJson)
    .then(res => {
      this.orderCreationCompleted();
    })
    .catch(err => {
      this.setState({buttonDisabled: '', errorMessage: "An error occurred. Failed to create order"});
    });
  }

  

  render() {
    return (

      <div className="container-fluid" style={{ padding: 0 }}>
      <div className="row bg-offwhite">
          <div className="col-md m-2 m-md-3">
            <div
              className="row"
            >
              <div
                className="col-4 col-md-6 col-lg-4 text-left">
                <img
                  src={rotipack}
                  style={{ width: "100%" }}
                  alt="Whole Grains Roti Pack"
                />
              </div>
              <div
                className="col-8 col-md-6 col-lg-8 text-left">
                <h3 className="text-primary">Introducing Chakki Chapati</h3>
                <p>
                  Chakki Chapati is a fresh, semi-cooked Chapati, made from Desi
                  Chakki Atta, that can be frozen for many weeks. Just heat on
                  pan, and you have fresh, hot, Rotis ready in minutes.
                  <br />
                  1 Pack contains 12 Rotis
                  <br />
                  Rs. {products.chakkiChapatiLarge.productCode}/- per pack
                </p>
              </div>
            </div>
          </div>
          <div className="col-md pr-0 pt-3 pr-md-5">
            <OrderComponent />
          </div>
        </div>  
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(withRouter(Order));