import React from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import OrderStep2 from "./OrderStep2";
import OrderStep3 from "./OrderStep3";
import ForgotPassword from "./ForgotPassword";
import WithHeader from "./WithHeaderComponent";
import OurStory from "./OurStory";
import Orders from "./admin/orders";
import Order from "./Order";
import Checkout from "./Checkout";
import Cart from "./Cart";
import OrderSummary from "./OrderSummary";
import RegisterConfirmation from "./RegisterConfirmation";
import Register from "./Register";
import Home from "./Home";
import Dashboard from "./dashboard/Dashboard";
import Faq from './Faq';
import Preparation from "./Preparation";
import ChakkiChapati from "./ChakkiChapati";
import Contacts from "./Contacts";
import Invoice from "./Invoice";

function Main() {
  return (
    <main>

        <Switch>
          <Route exact path='/'><WithHeader fixedHeader={true}><Home/></WithHeader></Route>
          <Route path='/register'><WithHeader><Register/></WithHeader></Route>
          <Route path='/order'><WithHeader><Order/></WithHeader></Route>
          <Route path='/cart'><WithHeader><Cart/></WithHeader></Route>
          <Route path='/checkout'><WithHeader><Checkout/></WithHeader></Route>
          <Route path='/ordersummary'><WithHeader><OrderSummary/></WithHeader></Route>
          <Route path='/orderstep2' component={OrderStep2}/>
          <Route path='/orderstep3' component={OrderStep3}/>
          <Route path='/confirmation'><WithHeader><RegisterConfirmation/></WithHeader></Route>
          <Route path='/forgotpassword' component={ForgotPassword}/>
          <Route path='/home'><WithHeader><Home/></WithHeader></Route>
          <Route path='/dashboard'><WithHeader><Dashboard/></WithHeader></Route>
          <Route path='/ourstory'><WithHeader><OurStory/></WithHeader></Route>
          <Route path='/faqs'><WithHeader><Faq/></WithHeader></Route>
          <Route path='/preparation'><WithHeader><Preparation/></WithHeader></Route>
          <Route path='/chakkichapati'><WithHeader><ChakkiChapati/></WithHeader></Route>
          <Route path='/contacts'><WithHeader><Contacts/></WithHeader></Route>
          <Route path='/invoice'><Invoice/></Route>
          <Route path='/admin/9beb026a-36a9-4e7d-ab50-631c2211ee1f' component={Orders}/>
          
        </Switch>
    </main>
  );
}


export default withRouter(Main);