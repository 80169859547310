import React from 'react';
import { Switch, Route } from 'react-router-dom'
import Order from "../Order";
import Orders from "./Orders";
import Subscription from "./Subscription";
import Rewards from "./Rewards";
import Profile from "./Profile";

function Dashboard() {
  return (
    <main className="container" style={{margin: 20}}>


<ul className="nav nav-tabs">
  <li className="nav-item">
    <a className="nav-link active" href="/dashboard/neworder">New Order</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" href="/dashboard/orders">Order History</a>
  </li>
</ul>

        <Switch>
          <Route exact path='/dashboard/neworder' component={Order}/>
          <Route exact path='/dashboard/orders' component={Orders}/>
          <Route exact path='/dashboard/subscription' component={Subscription}/>
          <Route exact path='/dashboard/rewards' component={Rewards}/>
          <Route exact path='/dashboard/Profile' component={Profile}/>
        </Switch>
    </main>
  );
}


export default Dashboard;