import deepExtend from "deep-extend";
import axios from 'axios';
import axiosconfig from '../axiosconfig';

// types

export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const ORDER_CREATED = "ORDER_CREATED";

// actions

export const addToCartAction = payload => ({
  type: ADD_TO_CART,
  payload
});

export const updateCartAction = payload => ({
  type: UPDATE_CART,
  payload
});

// operations

const deliveryCharges = 250;

const createOrder = orderRequest => dispatch => {
  return axios
    .post(
      axiosconfig.api2 + "/Order",
      orderRequest
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({'type': 'ORDER_CREATED'});
        return "";
      } else {
        return res.data;
      }
      }
    )
    .catch(err => {
      if (err && err.response && err.response.status){
        if (err.response.status===403){
          return "Could not create order";
        }
      }
      return err.message;
    });
};

const addToCart = requestObject => dispatch => {
  return dispatch(addToCartAction(requestObject));
};

const updateCart = requestObject => dispatch => {
  return dispatch(updateCartAction(requestObject));
};


export {
  addToCart,
  updateCart,
  createOrder
};

// reducer

const initialState = {
  items: [],
  deliveryCharges : deliveryCharges,
  amount: 0
};

const performCartOperation = (state, action, increment) => {
  let newItems = [];
  let amount = 0;
  let itemOfInterest = {productCode : action.payload.productCode, description: action.payload.description, title: action.payload.title, quantity: 0, productPrice: 0, subtotal: 0};
  if (state.items){
    state.items.forEach(element => {
      if (element.productCode === action.payload.productCode){
        deepExtend(itemOfInterest,element);
      } else {
        newItems.push(element);
      }
    });
  }
  const quantity = parseInt(action.payload.quantity);
  if (quantity>0){
    if (increment)
      itemOfInterest.quantity = parseInt(itemOfInterest.quantity) + parseInt(action.payload.quantity);
    else{
      itemOfInterest.quantity = quantity;
    }
    itemOfInterest.productPrice = action.payload.productPrice || itemOfInterest.productPrice;
    itemOfInterest.subtotal = parseInt(itemOfInterest.productPrice) * parseInt(itemOfInterest.quantity);
    itemOfInterest.title = action.payload.title || itemOfInterest.title;
    itemOfInterest.description = action.payload.description || itemOfInterest.description;
    newItems.push(itemOfInterest);
  }
  amount = 0
  var appliedDeliveryCharges = parseInt(deliveryCharges);
  newItems.forEach(element => {
    amount = amount + parseInt(element.subtotal); 
  });
  amount = amount + appliedDeliveryCharges;

  return deepExtend({}, state, {items: newItems, deliveryCharges: appliedDeliveryCharges, amount});
}; 

export default function cart(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      return performCartOperation(state, action, true);
    case UPDATE_CART:
      return performCartOperation(state, action, false);
    case ORDER_CREATED:
      return initialState;
    default:
      return state;
  }
}

// selectors

export function getCart(cart) {
    return cart;
}