import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import logo from "./images/plainlogo.png";
import loginbg from "./images/login-bg.jpg";
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';

class OrderStep3 extends Component {

  static defaultProps = {
    center: {
      lat: 33.53941600000001,
      lng: 73.09874900000001
    },
    zoom: 14
  };

  constructor(props) {
    super(props);
    this.state = {
      lat: props.center.lat,
      lng: props.center.lng,
      buttonDisabled: '',
      errorMessage: ''
    };
    this.handleSkip = this.handleSkip.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDrag(map) {
    this.setState({lat: map.center.lat(), lng: map.center.lng()});
  }

  handleSkip(event) {
    document.location.href = "/confirmation";
  }

  handleSubmit(event) {
    event.preventDefault();
    let request = {
      id: this.props.id,
      latitude: this.state.lat,
      longitude: this.state.lng
    }
    this.setState({buttonDisabled: 'disabled', errorMessage: ""});
    axios.post('https://us-central1-wholegrains-215319.cloudfunctions.net/updateUserAddress', request)
      .then(res => {
        document.location.href = "/confirmation";
      })
      .catch(error => {
        this.setState({buttonDisabled: '', errorMessage: "An error occurred. Failed to updated location"});
        console.log(error);
      });
  }

  render() {
    return (

      <div className="App" style={{width:'100%', display: 'flex', justifyContent: 'center', margin: 10 }}>
        

        <div className="container-fluid col-xl-6 col-lg-10 col-md-10 col-sm-11 col-xs-12 bg-light shadow opaque" style={{borderRadius: '21px'}}>
        
        <div className="parallax" style={{position: 'fixed', top: 0, left: 0, width: '100%', zIndex: -1, backgroundImage: 'url('+loginbg + ')'}}></div>

        <div className="my-2">
          <img src={logo} alt="whole grains logo" style={{width: '10rem'}}></img>
        </div>

        <div className="bg-light mx-auto">

        <form className="needs-validation" onSubmit={this.handleSubmit}>
        <br/>
        
        <div className="row text-left my-2">
          
          <div className="col-10 offset-1">
            <p>
              <strong>
                Order Placed Successfully!
              </strong>
            </p>
            <p>
              Please zoom and drag the map below to locate your delivery location to the center of the green marker. This will save our delivery person a lot of time in finding the exact location of your house.
              Skipping this may cause delay or missed deliveries if your house cannot be found by the delivery man.
            </p>
          </div>
        </div>

        <div className="row text-dark text-left my-2">
          <div className="col-10 offset-1">
            <h5>Delivery Location</h5>
            Lat: <input type="text" id="lat" name="lat" readOnly="readonly" disabled="disabled" value={this.state.lat} /> {" "}
            Lng: <input type="text" id="lng" name="lng" readOnly="readonly" disabled="disabled" value={this.state.lng} /> 
          </div>
        </div>

        <div className="row text-dark text-left my-2">
        
        <div style={{ position: 'relative', paddingTop: '100%', width: '100%'}}>
        <div style={{position: 'absolute', top: '50%', left: '50%', width: '50%', height: '2px', transform: 'translate(-50%, 0%)', zIndex: 100, backgroundColor: 'green'}}></div>
        <div style={{position: 'absolute', top: '50%', left: '50%', width: '2px', height: '50%', transform: 'translate(-0%, -50%)', zIndex: 100, backgroundColor: 'green'}}></div>


          
          <div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDB2fKsp1bJ-Pn5p0pZLrwKGcwB4s9SZ5g' }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              onDrag = {this.handleDrag}
            >             
            </GoogleMapReact>
          </div>
        </div>

        </div>

        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            &nbsp;
          </div>
        </div>

        
        <div className="row text-dark text-left">
          <div className="col-10 offset-1">
            &nbsp;
          </div>
        </div>

        <div className="row text-dark text-left my-2">
          <div className="col-7 offset-1">
            <input type="submit" value="Set Location" className="btn btn-primary btn-lg btn-block" 
             disabled={this.state.buttonDisabled}
             style={{backgroundColor: '#98511E', borderColor: '#98511E', display: 'inline-block'}}/>
          </div>
          <div className="col-3">
            <input type="button" value="Skip" className="btn btn-primary btn-lg btn-block" onClick={this.handleSkip} style={{backgroundColor: '#98511E', borderColor: '#98511E', display: 'inline-block'}}/>
          </div>
        </div>

        <p>&nbsp;</p>
        </form>
        </div>
        </div>
        <p>&nbsp;</p>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    id: state.user.id
  }
}

const mapDispatchToProps = {  }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderStep3));