import React, { Component } from 'react';

export default class Invoice extends Component {

  render() {
    return (
        <div>
        BILL OF SALE
        </div>
    );
  }
}