import React, { Component } from "react";
import Stepper from "./StepperComponent";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {Redirect} from "react-router";
import packfront from "./images/pack-front.png";
import bannersmall from "./images/banner-small.png";
import mobilebanner from "./images/mobile-banner.png";
import packmix from "./images/pack-mix.png";
import packdiet from "./images/pack-diet.png";
import packfortified from "./images/pack-fortified.png";
import rotidisplay from "./images/roti-display.png";
import icon_chakkiatta from "./images/icon_chakkiatta.png";
import icon_nopreservatives from "./images/icon_nopreservatives.png";
import icon_natural from "./images/icon_natural.png";
import icon_readyinminutes from "./images/icon_readyinminutes.png";
import icon_canbefrozen from "./images/icon_canbefrozen.png";
import icon_tasteslikehome from "./images/icon_tasteslikehome.png";
import icon_maps from "./images/icon_maps.png";
import card_chapati from "./images/card_chapati.png";
import card_diet from "./images/card_diet.png";
import card_mix from "./images/card_mix.png";
import card_fortified from "./images/card_fortified.png";
import { addToCart } from "./modules/cart";
import products from "./modules/products";
import axiosconfig from "./modules/axiosconfig";
import withSizes from 'react-sizes';
import { bindActionCreators } from "redux";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantitySingle: 2,
      quantityDiet: 2,
      quantityMixed: 2,
      quantityFortified: 2,
      redirectToCart: false
    };
    this.onOrder = this.onOrder.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user.isLoggedIn) {
      // Check if old token
      if (this.props.user.phone.value){
        console.log("Logging out old user")
        this.props.dispatch({ type: 'LOGOUT' });
      }
    }
  }

  updateQuantitySingle = (newQuantity) => {
    this.setState({ quantitySingle: newQuantity });
  };

  updatequantityDiet = (newQuantity) => {
    this.setState({ quantityDiet: newQuantity });
  };

  updatequantityFortified = (newQuantity) => {
    this.setState({ quantityFortified: newQuantity });
  };

  updatequantityMixed = (newQuantity) => {
    this.setState({ quantityMixed: newQuantity });
  };

  orderButtonStyles = {
    marginTop: 15,
    width: 140,
    paddingLeft: 5,
    paddingRight: 5
  };

  holidayBreakStyles = {
    color: "#BA581D",
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 5,
    display: "inline-block"
  };

  priceStyles = {
    color: "#BA581D",
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 5,
    display: "inline-block"
  };

  holidayStyles = {
    color: "#BA581D",
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 5,
    display: "inline-block"
  };

  onOrder = function(code){
    var item = {}
    if (code===products.chakkiChapatiLarge.productCode){
      item = {
        productCode: products.chakkiChapatiLarge.productCode,
        productPrice: products.chakkiChapatiLarge.price,
        quantity: this.state.quantitySingle,
        title: products.chakkiChapatiLarge.title,
        description: products.chakkiChapatiLarge.description
      };
    }
    else if (code===products.chakkiChapatiMixed.productCode){
      item = {
        productCode: products.chakkiChapatiMixed.productCode,
        productPrice: products.chakkiChapatiMixed.price,
        quantity: this.state.quantityMixed,
        title: products.chakkiChapatiMixed.title,
        description: products.chakkiChapatiMixed.description
      };
    }
    else if (code===products.chakkiChapatiFortified.productCode){
      item = {
        productCode: products.chakkiChapatiFortified.productCode,
        productPrice: products.chakkiChapatiFortified.price,
        quantity: this.state.quantityFortified,
        title: products.chakkiChapatiFortified.title,
        description: products.chakkiChapatiFortified.description
      };
    }
    else if (code===products.chakkiChapatiDiet.productCode){
      item = {
        productCode: products.chakkiChapatiDiet.productCode,
        productPrice: products.chakkiChapatiDiet.price,
        quantity: this.state.quantityDiet,
        title: products.chakkiChapatiDiet.title,
        description: products.chakkiChapatiDiet.description
      };
    }
    this.props.addToCart(item)
    this.setState({redirectToCart: true});
    //if (this.props.user.isLoggedIn)
    
    //else
    //  document.location = "login";
  };

  render() {
    const iconStyle = {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      color: "#83ad59",
      fontFamily: "gothamMedium",
      width: 200,
      height: 200
    };

    const stores = [
      {
        id: "RWPDHAJMRT",
        name: "JMart",
        desc: "DHA 2 - Islamabad",
        lat: 33.5329624,
        lng: 73.1628799
      },
      {
        id: "ISBHWYMDM",
        name: "Madina Mart",
        desc: "Fizaia - Islamabad",
        lat: 33.616964,
        lng: 73.120104
      },
      {
        id: "RWPBAHDVM",
        name: "DV Mart",
        desc: "Bahria 7 - Rawalpindi",
        lat: 33.5153506,
        lng: 73.0846358
      },
      {
        id: "RWPBAHFPR",
        name: "Fair Price Stores",
        desc: "Bahria 7 - Rawalpindi",
        lat: 33.5157191,
        lng: 73.0839716
      },
      {
        id: "RWPBAHBST",
        name: "Al-Basit Store",
        desc: "Bahria 1 - Rawalpindi",
        lat: 33.5668934,
        lng: 73.1144958
      },
      {
        id: "RWPBAHBST2",
        name: "Al-Basit Store",
        desc: "Bahria 4 - Rawalpindi",
        lat: 33.5668934,
        lng: 73.1144958
      },
      {
        id: "RWPBAHSPS",
        name: "Star Premium Store",
        desc: "Bahria 8 - Rawalpindi",
        lat: 33.5016414,
        lng: 73.108426
      },
      {
        id: "ISBSHN001",
        name: "Sheheen Chemist & Grocers",
        desc: "F-10/4 - Islamabad",
        lat: 33.632668,
        lng: 72.9244586
      },
      {
        id: "ISBSHN002",
        name: "Sheheen Chemist & Grocers",
        desc: "G-11 - Islamabad",
        lat: 33.632668,
        lng: 72.9244586
      },
      {
        id: "ISBSHN003",
        name: "Sheheen Chemist & Grocers",
        desc: "Bahria Enclave - Islamabad",
        lat: 33.632668,
        lng: 72.9244586
      },
      {
        id: "ISBSHN004",
        name: "Sheheen Chemist & Grocers",
        desc: "F-6 Markaz - Islamabad",
        lat: 33.632668,
        lng: 72.9244586
      },
      {
        id: "ISBSHN005",
        name: "Sheheen Chemist & Grocers",
        desc: "I-8 Time square - Islamabad",
        lat: 33.632668,
        lng: 72.9244586
      }

    ];

    return (
      <>
        {this.state.redirectToCart && <Redirect to="/cart"/>}
        <section id="intro1">
          <div className="container">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div className="row justify-content-center align-self-center">
                <img src={bannersmall} alt="" className="img-fluid d-md-block d-none" />
                <img src={mobilebanner} alt="" className="img-fluid d-md-none d-block" />
            </div>
          </div>
        </section>

        <section id="services" className="section-bg">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-6 col-lg-3 wow bounceInUp"
                  data-wow-duration="1.4s"
                >
                  <div className="box">
                    <a href="#order"><img src={card_chapati} alt="Card" /></a>
                    <div className="text-section">
                      <h3 className="title">
                        <a href="/">Chakki Chapati<br/>Regular12 Pack</a>
                      </h3>
                      <p className="description">
                        Our flagship product, made from fresh, chakki atta and
                        tastes like home-made Roti
                      </p>
                      <div
                        style={{
                          color: "#7E4D2D",
                          fontSize: 22,
                          textAlign: "left"
                        }}
                      >
                        <span
                          style={this.priceStyles}
                        >
                          PKR {products.chakkiChapatiLarge.price} / pack
                        </span>
                        <div style={{ fontWeight: "bold", fontSize: 14 }}>
                          Quantity
                        </div>
                        <div style={{ width: 140 }}>
                          <Stepper
                            onChange={this.updateQuantitySingle}
                            initialValue={2}
                          ></Stepper>
                        </div>
                        {axiosconfig.enabled && <button
                          className="btn btn-primary"
                          onClick={() => this.onOrder("CCL101")}
                          style={this.orderButtonStyles}
                        >
                          ADD TO CART
                        </button>}
                        {!axiosconfig.enabled && <div style={this.holidayStyles}>
                          NOTE: Online orders are currently disabled.
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 wow bounceInUp"
                  data-wow-duration="1.4s"
                >
                  <div className="box">
                    <img src={card_fortified} alt="Card" />
                    <div className="text-section">
                      <h4 className="title">
                        <a href="/">Fortified Chapati<br/>10 Pack</a>
                      </h4>
                      <p className="description">
                        The same Chapati made with whole, fortified wheat with added nutrients
                      </p>
                      <div
                        style={{
                          color: "#7E4D2D",
                          fontSize: 22,
                          textAlign: "left"
                        }}
                      >
                        <span
                          style={this.priceStyles}
                        >
                          PKR {products.chakkiChapatiFortified.price} / pack
                        </span>
                        <div style={{ fontWeight: "bold", fontSize: 14 }}>
                          Quantity
                        </div>
                        <div style={{ width: 140 }}>
                          <Stepper
                            onChange={this.updatequantityFortified}
                            initialValue={2}
                          ></Stepper>
                        </div>
                        {axiosconfig.enabled && <button
                          className="btn btn-primary"
                          onClick={() => this.onOrder("CCM004")}
                          style={this.orderButtonStyles}
                        >
                          ADD TO CART
                        </button>}
                        {!axiosconfig.enabled && <div style={this.holidayStyles}>
                          HOLIDAY BREAK
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 wow bounceInUp"
                  data-wow-duration="1.4s"
                >
                  <div className="box">
                    <img src={card_mix} alt="Card" />
                    <div className="text-section">
                      <h4 className="title">
                        <a href="/">Mixed Grain<br/>Chapati 10 Pack</a>
                      </h4>
                      <p className="description">
                        A delicious blend of mixed grains combining flavor with
                        nutrients
                      </p>
                      <div
                        style={{
                          color: "#7E4D2D",
                          fontSize: 22,
                          textAlign: "left"
                        }}
                      >
                        <span
                          style={this.priceStyles}
                        >
                          PKR {products.chakkiChapatiMixed.price} / pack
                        </span>
                        <div style={{ fontWeight: "bold", fontSize: 14 }}>
                          Quantity
                        </div>
                        <div style={{ width: 140 }}>
                          <Stepper
                            onChange={this.updatequantityMixed}
                            initialValue={2}
                          ></Stepper>
                        </div>
                        {axiosconfig.enabled && <button
                          className="btn btn-primary"
                          onClick={() => this.onOrder("CCL005")}
                          style={this.orderButtonStyles}
                        >
                          ADD TO CART
                        </button>}
                        {!axiosconfig.enabled && <div style={this.holidayStyles}>
                          HOLIDAY BREAK
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-3 wow bounceInUp"
                  data-wow-duration="1.4s"
                >
                  <div className="box">
                    <img src={card_diet} alt="Card" />
                    <div className="text-section">
                      <h4 className="title">
                        <a href="/">Diet Chapati<br/>10 Pack</a>
                      </h4>
                      <p className="description">
                        The same wholesome Chapati in a smaller size for weight watchers.
                      </p>
                      <div
                        style={{
                          color: "#7E4D2D",
                          fontSize: 22,
                          textAlign: "left"
                        }}
                      >
                        <span
                          style={this.priceStyles}
                        >
                          PKR {products.chakkiChapatiDiet.price} / pack
                        </span>
                        <div style={{ fontWeight: "bold", fontSize: 14 }}>
                          Quantity
                        </div>
                        <div style={{ width: 140 }}>
                          <Stepper
                            onChange={this.updatequantityDiet}
                            initialValue={2}
                          ></Stepper>
                        </div>
                        {axiosconfig.enabled && <button
                          className="btn btn-primary"
                          onClick={() => this.onOrder("CCM006")}
                          style={this.orderButtonStyles}
                        >
                          ADD TO CART
                        </button>}
                        {!axiosconfig.enabled && <div style={this.holidayStyles}>
                          HOLIDAY BREAK
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>

        <main id="main">
          <section id="about" style={{ backgroundColor: "#F9EFA9" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="about-img">
                    <img src={rotidisplay} alt="logo" />
                  </div>
                </div>

                <div className="col-lg-7 col-md-6 text-left">
                  <div className="about-content">
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ paddingBottom: 20, color: "#83ad59" }}
                      >
                        Our flagship product. Whole Wheat Chakki Atta Chapatis.
                        Semi-Cooked, Home-Style Roti. Just Heat and Eat!
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle} className="center-block">
                          <img
                            src={icon_chakkiatta}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          MADE FROM <br />
                          PURE CHAKKI ATTA
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle}>
                          <img
                            src={icon_nopreservatives}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          NO <br />
                          PRESERVATIVES
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle}>
                          <img
                            src={icon_natural}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          ALL NATURAL <br />
                          INGREDIENTS
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle}>
                          <img
                            src={icon_readyinminutes}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          READY IN <br />
                          MINUTES
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle}>
                          <img
                            src={icon_canbefrozen}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          CAN BE FROZEN <br />
                          FOR MONTHS
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-4">
                        <div style={iconStyle}>
                          <img
                            src={icon_tasteslikehome}
                            alt="logo"
                            className="benefits-icon"
                          />
                          <br />
                          TASTES LIKE <br />
                          HOME-MADE ROTI
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="features">
            <div className="container">
              <header className="section-header" id="storelocator">
                <h3>STORE LOCATOR</h3>
                <p>&nbsp;</p>
              </header>
              <div className="row feature-item">
                <div className="col-lg-6 wow fadeInUp text-left">
                  {stores.map(x => {
                    return (
                      <div style={{ marginBottom: 10 }} key={x.id}>
                        <b>{x.name}</b>
                        <br />
                        {x.desc}{" "}
                        <a
                          style={{ color: "#D2B883" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://maps.google.com/?q=" + x.lat + "," + x.lng
                          }
                        >
                          [<img alt="map icon" src={icon_maps} />
                          &nbsp;Directions]
                        </a>
                        <br />
                      </div>
                    );
                  })}
                </div>
                <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                  <iframe
                    title="map"
                    src="https://snazzymaps.com/embed/208980"
                    width="100%"
                    height="400px"
                    style={{ border: "none" }}
                  ></iframe>
                </div>
              </div>
            </div>
          </section>

          <section id="call-to-action" className="wow fadeInUp">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 text-center text-lg-left">
                  <h3 className="cta-title">Easy Preparation</h3>
                  <div className="cta-text">
                    <br />
                    <p>1. Pull out frozen chapati by separator sheet.</p>
                    <p>2. Heat on a Pan from both sides</p>
                    <p>3. Optionally, place on raw fire for few moments</p>
                  </div>
                </div>
                <div className="col-lg-9 cta-btn-container text-center">
                  <iframe
                    title="Chapati Preparation Video - Main"
                    width="100%"
                    src="https://www.youtube.com/embed/N6r1jS2j__0?si=B2ZAzVyDf_cOqAtE"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>

          <section id="faq">
            <div className="container">
              <header className="section-header">
                <h3>Frequently Asked Questions</h3>
                <p>&nbsp;</p>
              </header>

              <ul id="faq-list" className="wow fadeInUp">
                <li>
                  <a data-toggle="collapse" className="collapsed" href="#faq1">
                    HOW TO PREPARE CHAPATIS?{" "}
                    <i className="ion-android-remove"></i>
                  </a>
                  <div id="faq1" className="collapse" data-parent="#faq-list">
                    <p>
                      Heat the chapatis on a pan for thirty seconds each side,
                      and then give them heat directly on flame to make the
                      Chapatis inflate with steam ('Phooli Chapati').
                      <br />
                      Frozen or refrigerated Chapati can be heated directly, and
                      do not need a thawing period.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq2" className="collapsed">
                    HOW LONG DO CHAPATIS REMAIN FRESH?{" "}
                    <i className="ion-android-remove"></i>
                  </a>
                  <div id="faq2" className="collapse" data-parent="#faq-list">
                    <p>
                      Since they are semi-cooked, they remain fresh in room
                      temperature for many hours.
                      <br />
                      In refrigerator, we recommend consuming them in four days.
                      <br />
                      It is best to put them in the freezer, where they can
                      remain fresh for many weeks.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq3" className="collapsed">
                    CAN WE FREEZE THE CHAPATIS?{" "}
                    <i className="ion-android-remove"></i>
                  </a>
                  <div id="faq3" className="collapse" data-parent="#faq-list">
                    <p>
                      Yes. We recommend storing the Chapatis in a freezer, where
                      they can be stored for many weeks. The advantage With
                      frozen chapatis is that they can directly be heated, and
                      do not need a thawing period.
                      <br />
                      They can also be refrigerated and remain fresh at least
                      four days.
                    </p>
                  </div>
                </li>

                <li>
                  <a data-toggle="collapse" href="#faq4" className="collapsed">
                    DO YOU DO HOME DELIVERIES?{" "}
                    <i className="ion-android-remove"></i>
                  </a>
                  <div id="faq4" className="collapse" data-parent="#faq-list">
                    <p>
                      Yes. We deliver in Rawalpindi &amp; Islamabad.
                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </section>

          <section id="whyus" className="wow fadeIn section-bg">
            <div className="container-fluid">
              <header className="section-header">
                <h3>WHY WHOLE GRAINS</h3>
                <p>&nbsp;</p>
              </header>
              <div className="row">
                <div className="col-1 d-none d-sm-block"></div>
                <div className="col-12 col-sm-5">
                  <p>
                    In 2017, WholeGrains pioneered the idea of Semi-Cooked
                    Chapatis in Pakistan, that can combine convenience without
                    compromising on quality and wholesomeness. Our facebook
                    research brought overwhelming appreciation for the idea, and
                    hence we moved back to Pakistan to start Whole Grains, a
                    mission driven company whose goal is to bring wholesome,
                    quality food products to the community, where no woman has
                    to ever make a choice between convenience vs the health of
                    her loved ones.
                  </p>
                  <p>
                    In today’s urban lifestyle, especially in the busy schedule
                    of our housewives and working women, it is increasingly
                    difficult to find time for kneading the flour and preparing
                    the dough for making Rotis, and add to that the difficulty
                    of finding a reliable source of whole wheat. But at the same
                    time, we love our children, and we don’t want to compromise
                    on their health and nutrition to substitute their diet with
                    processed foods full of additives, and preservatives.
                  </p>
                </div>
                <div className="col-12 col-sm-5">
                  <p>
                    With WholeGrains, you get the same ingredients we choose for
                    our own children, packed in a convenience that you can never
                    beat.
                  </p>
                  <p>
                    Our commitment to you is that we will never compromise on
                    the quality of our ingredients and the integrity of our
                    product's Wholesomeness and purity.
                  </p>
                </div>
                <div className="col-1 d-none d-sm-block"></div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    cart: state.cart
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addToCart: bindActionCreators(addToCart, dispatch)
  };
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null,
  {
    pure: false
  })(withSizes(mapSizesToProps)(Home)));
