const configs = {
  "localhost": {
    api2: "https://localhost:5001",
    enabled: true
  },
  "wholegrains.pk": {
    api2: "https://wholegrains.pk/api",
    enabled: true
  },
  "www.wholegrains.pk": {
    api2: "https://www.wholegrains.pk/api",
    enabled: true
  }
};

function config() {
  return configs[window.location.hostname];
}

export default config();
