import React, { Component } from 'react';
import axiosconfig from "./modules/axiosconfig";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as moment from 'moment';
import rotipack from "./images/P01-Icon.png";
import './Order.css';
import Stepper from "./StepperComponent";
import { bindActionCreators } from "redux";
import { updateCart } from "./modules/cart";
import colors from "./colors";

class Cart extends Component {

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.deliveryWindows = [];

    let quantitiesValid = true;
    props.cart.items.forEach(element => {
      if (quantitiesValid)
      {
        if (element.quantity<2)
        {
          quantitiesValid = false;
        }
      }
    });

    this.state = {
      email: '',
      name: '',
      phone: '',
      whatsapp: '',
      street: '',
      latitude: 33.539416,
      longitude: 73.098749,
      addressNotes: '',
      packCharges: 0,
      personId: 0,
      buttonValue: 'Place Order',
      buttonDisabled: '',
      errorMessage: '',
      quantity: 0,
      deliveryCharge: 250,
      createdDate: moment().format('ddd D MMM, YYYY'),
      productPrice: 0,
      status: 0,
      password: '',
      loginButtonDisabled: '',
      passwordClass: 'form-control',
      phoneInvalid: 'none',
      phoneInvalidMessage: 'Phone is invalid',
      quantitiesValid: quantitiesValid
    };

    if (!this.props.user.isLoggedIn){
      //document.location.href="/login";
    }
  }

  handleRemove = (e, productCode) => {
    this.props.updateCart({
          productCode: productCode,
          quantity: 0
    });
  };

  updateCart = (productCode, quantity) => {
    this.setState({quantitiesValid:(quantity >= 2)})
    const item =  {
          productCode: productCode,
          quantity: quantity
    };
    this.props.updateCart(item);
  }

  compare( a, b ) {
    if ( a.productCode < b.productCode ){
      return -1;
    }
    if ( a.productCode > b.productCode ){
      return 1;
    }
    return 0;
  }
  
  render() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-12">
                &nbsp;<br/>&nbsp;
            </div>
        </div>
        <header className="section-header" style={{marginBottom: 25}}>
          <h3>Shopping Cart</h3>
        </header>
        
        {
          this.props.cart.items.sort(this.compare).map(item => {
            return (
              <div className="d-block d-md-none" key={item.productCode}>
                <div key={item.productCode} className="row" style={{borderTop: '1px solid #D2B883', paddingTop: 12, marginBottom: 10, fontFamily: 'gotham', fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
                  <div className="col-4" style={{textAlign: 'center'}}>
                    <img
                        src={rotipack}
                        style={{ width: '100%', padding: 3, marginRight: 2, float: 'left' }}
                        alt="Whole Grains Roti Pack"
                    />
                    <button onClick={(e) => this.handleRemove(e, item.productCode)} className="btn-link">
                        (REMOVE)
                    </button>
                  </div>
                  <div className="col-8" style={{textAlign: 'left'}}>
                    <span className="btn-link">SKU: {item.productCode}<br/></span>
                    {item.title}<br/>
                    <span style={{fontFamily: 'gothamXLight'}}>{item.description}<br/></span>
                    <div style={{backgroundColor: colors.lightestBrown, fontFamily: 'gothamXLight', padding: 4, fontSize: 11}}>DELIVERY CHARGES MAY INCREASE BASED ON DISTANCE. FACEBOOK PROMOTIONS ARE APPLIED ON DELIVERY</div>
                  </div>
                </div>
                <div className="row" style={{marginBottom: 5, fontSize: 12, fontWeight: 'bold', 
                  color: '#7E4D2D'}}>
                  <div className="col-4">
                  PRICE
                  </div>
                  <div className="col-4">
                  QUANTITY
                  </div>
                  <div className="col-4">
                  SUBTOTAL
                  </div>
                </div>
                <div className="row" style={{fontFamily: 'gothamXLight', color: '#7E4D2D', fontSize: 14}}>
                  <div className="col-4">
                    PKR. {item.productPrice}
                  </div>
                  <div className="col-4">
                    <Stepper onChange={this.updateCart} initialValue={item.quantity} forProduct={item.productCode}></Stepper>
                  </div>
                  <div className="col-4">
                    PKR. {item.subtotal}
                  </div> 
                </div>
              </div>
            )
          })
        }

        <div className="d-none d-md-block">

          <div className="row" style={{borderBottom: '1px solid #D2B883', marginBottom: 20, fontSize: 12, fontWeight: 'bold', 
              color: '#7E4D2D'}}>
              <div className="col-3">
              ITEM
              </div>
              <div className="col-3">
              PRICE
              </div>
              <div className="col-3">
              QUANTITY
              </div>
              <div className="col-3">
              SUBTOTAL
              </div>
          </div>
          {
            this.props.cart.items.map(item => {
              return (
                <div key={item.productCode} className="row" style={{fontFamily: 'gothamXLight', fontWeight: 'bold', color: '#7E4D2D', fontSize: 14}}>
                  <div className="col-3" style={{textAlign: 'left'}}>
                    <img
                        src={rotipack}
                        style={{ width: 100, padding: 3, marginRight: 5, float: 'left' }}
                        alt="Whole Grains Roti Pack"
                    />
                    {item.productCode}<br/>
                    {item.title}<br/>
                    {item.description}<br/>
                    
                    <button onClick={(e) => this.handleRemove(e, item.productCode)} className="btn-link">
                        (Remove)
                    </button>
                  </div>
                  <div className="col-3">
                    PKR. {item.productPrice}
                  </div>
                  <div className="col-3">
                    <Stepper onChange={this.updateCart} initialValue={item.quantity} forProduct={item.productCode}></Stepper>
                    <br/>
                    <div style={{backgroundColor: colors.lightestBrown, fontFamily: 'gothamXLight', padding: 4, fontSize: 11}}>DELIVERY CHARGES MAY INCREASE BASED ON DISTANCE. FACEBOOK PROMOTIONS ARE APPLIED ON DELIVERY</div>
                    {
                      !this.state.quantitiesValid && <span>Minimum quantity is 2</span>
                    }
                  </div>
                  <div className="col-3">
                    PKR. {item.subtotal}
                  </div> 
                </div>
              )
            })
          }
          </div>
        {
          this.props.cart.items.length>0 && 
          (
            <div className="row">
                <div className="col-12" style={{padding: 20, textAlign: 'right'}}>
                    <button disabled={!axiosconfig.enabled || !this.state.quantitiesValid} onClick={e => document.location.href = '/checkout'} className="btn btn-primary">CHECKOUT</button>
                </div>
            </div>
          )
        }
        {
          this.props.cart.items.length===0 && 
          (
            <div className="row">
                <div className="col-12">
                  Your cart is empty.
                </div>
            </div>
          )
        }
        <div className="row">
          <div className="col-12">
            <a href="/#products">Continue Shopping</a>
          </div>
        </div>
        
        <div className="row">
            <div className="col-12" style={{height: '25vh'}}>
                &nbsp;<br/>&nbsp;<br/>&nbsp;
            </div>
        </div>
      </div>);
    }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCart: bindActionCreators(updateCart, dispatch)
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps, null,
  {
    pure: false
  })(Cart));